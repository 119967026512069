.header-navbar.floating-nav {
  position: fixed;
  right: 0;
  margin: 0rem;
  width: calc(100vw - (100vw - 100%));
  border-radius: 0.428rem;
  z-index: 1032;
}
.card {
  margin-bottom: 1rem !important;
}
select.form-control:not([multiple="multiple"]) {
  padding-right: 2.5rem !important;
}
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
  width: 245px !important;
}
nav.header-navbar.navbar.navbar-expand-lg.align-items-center.floating-nav.navbar-light.navbar-shadow.customCss {
  width: 100% !important;
}

@font-face {
  font-family: "dashboard-font";
  src: url("BeVietnamPro-SemiBold.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}
@font-face {
  font-family: "dashboard-font";
  src: url("BeVietnamPro-Regular.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
  font-weight: 400;
}

.dashboardFont {
  font-family: dashboard-font;
  font-size: 47px;
}

.big-block,
.small-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #f6f6f6;
  border: 1px solid #dbd8d8;
  border-radius: 8px;
  padding: 24px;
}

.text-wrapper {
  font-weight: 400;
}

.text-wrapper * {
  line-height: 1.5;
  font-size: 24px;
  color: black;
}

.header1 {
  font-family: dashboard-font;
  font-weight: 600;
  font-size: 36px;
  line-height: 42.19px;
}

.text400 {
  font-family: dashboard-font;
  font-size: 24px;
  font-weight: 400;
}

.action-button {
  background: black;
  color: white;
  text-transform: none;
  width: fit-content;
  border: 0px solid;
  border-radius: 24px;
  align-items: center;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 500;
  align-self: flex-end;
}

.action-button:hover {
  color: #333;
}
